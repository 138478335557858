@import 'media';

.profile-data-tile {
    display: flex;
    gap: 2.25rem;
    padding: 1.53rem 2.09rem 1.53rem 1.75rem;
    box-shadow: 2px 4px 12px var(--shadow-color-primary);
    border-radius: 0.5rem;
    max-width: 95rem;
    margin: auto;
    flex-wrap: wrap;
    overflow: hidden;

    @include xs {
        padding: 0.81rem;
        gap: 0.81rem;
    }

    &__type {
        box-sizing: border-box;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 9rem;
        gap: 0.62rem;
        padding: 1rem 0;

        @include xs {
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%;
            border-bottom: 2px solid var(--mat-divider-color);

            & + .mat-divider {
                display: none;
            }
        }
    }

    &__type-name {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        white-space: nowrap;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 0.44rem;
        flex: 1 0 0;

        @include xs {
            width: 100%;
        }
    }

    &__body {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__divider {
        height: 0.325rem;
        width: 0.325rem;
    }

    &__grid-checkbox {
        display: flex;
        flex-wrap: wrap;
        color: var(--text-primary);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        gap: 0 0.5rem;

        .mat-mdc-checkbox {
            white-space: nowrap;
        }
    }

    .input-group-label {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
    }

    .text-inputs {
        display: flex;
        gap: 1.75rem;
        flex-wrap: wrap;
    }

    .select-inputs {
        display: flex;
        flex-direction: column;
        gap: 1.225rem;
    }

    .label-title {
        color: var(--Primary-900, #5C5EB2);
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;
        white-space: nowrap;
    }

    .content-title {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
    }

    .checkbox-container {
        margin-top: 0.81rem;
        margin-left: -0.41rem;
    }

    .divider {
        border-width: 2px;
    }

    .profile-icon {
        height: 2rem;
    }

    .other-school {
        margin-top: -0.325rem;
    }

    .info-cloud-box {
        display: flex;
        flex-direction: column;
        gap: 1.325rem;
        padding: 1.125rem 1.56rem;
        background: var(--neutral-bg-light);
        border-radius: 1rem;
    }
}
