@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import 'material-theme-variables';
@import '../styles/material-calendar-my-gwo-styles';
@import "profile-data-tile";
@import "media";

html {
    height: 100%;

    @include hd {
        font-size: 20px !important;
    }

    @include lg {
        font-size: 14px !important;
    }

    @include md {
        font-size: 12px !important;
    }
}

b {
    font-weight: 700;
}

.gwo-button {
    .mdc-button__label {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        width: 100%;
    }
}

.event-tile__action-button {
    .mdc-button__label {
        z-index: 0 !important;
    }
}

@-moz-document url-prefix() {
    html {
      scrollbar-color: var(--primary-200) transparent;
    }
  }

* {
    &::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--primary-200);
        border-radius: 8px;
        min-height: 5rem;
    }

      &::-webkit-scrollbar-track  {
        background: transparent;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    color: var(--text-primary);
    height: 100%;

    .mat-drawer-container {
        color: var(--text-primary);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-primary);
}

a {
    color: var(--link-color);
    text-decoration: none;

    &:active {
        color: var(--text-primary);
    }
}

.mdc-button {

    .mat-icon {
        z-index: 1;
    }
}

a.btn-primary,
button.btn-primary {
    border-radius: 2rem;
    padding: 0.225rem 1.725rem;

    &.mat-mdc-unelevated-button:not(:disabled) {
        background: var(--primary-bg-dark);
    }

    .mat-icon {
        color: var(--color-white);
    }

    .mdc-button__label {
        color: var(--color-white);
    }
}

a.btn-primary-o,
button.btn-primary-o {
    border-radius: 2rem;
    padding: 0.225rem 1.725rem;
    border: 2px solid var(--primary-bg-dark);

    &.mat-mdc-unelevated-button:not(:disabled) {
        background: transparent;
    }

    .mat-icon {
        color: var(--text-primary);
    }

    .mdc-button__label {
        color: var(--text-primary);
    }
}

a.btn-warn,
button.btn-warn {
    border-radius: 2rem;
    padding: 0.225rem 1.725rem;

    .mdc-button__label,
    .mat-icon {
        color: var(--color-white);
    }
}

.mat-mdc-paginator.table-paginator {
    background: none;
}

.mdc-button__label {
    text-align: left;
}

.white-icon {
    color: var(--color-white);
}

.primary-icon {
    color: var(--text-primary);
}

.footer__menu-item .mdc-list-item__primary-text {
    color: var(--primary-850);
}

.footer__menu-item:active::before,
.footer__menu-item:focus::before,
.footer__menu-item:hover::before {
    content: none !important;
}

.gwo-tabs {

    &.mat-mdc-tab-group {
        height: 100%;

        &.mat-mdc-tab-group-stretch-tabs {
            &>.mat-mdc-tab-header {

                .mat-mdc-tab-list {
                    border-bottom: 0.1875rem solid var(--border-light);
                }

                .mat-mdc-tab {
                    flex-grow: unset;
                    padding: 0 0 0.5rem 0;
                    min-width: auto;
                    height: auto;
                    font-size: 1.3rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 2.1875rem;
                    transition: text-shadow 0.1s;

                    .mdc-tab__ripple::before {
                        background: none;
                    }

                    .mdc-tab-indicator__content--underline {
                        border-top-width: 0.25rem;
                        margin-bottom: -2px;
                    }

                    .mdc-tab__text-label {
                        color: var(--text-primary);
                    }

                    &:hover{

                        .mdc-tab__text-label {
                            color: var(--primary-950);
                        }
                    }

                    .mat-mdc-tab-ripple {
                        display: none;
                    }
                }
            }
        }

        .mat-mdc-tab-labels {
            gap: 0 2rem;
        }

        .mat-mdc-tab-disabled {
            display: none;
        }
    }

    .mat-mdc-tab-header-pagination-controls-enabled {
        .mat-mdc-tab-header-pagination {
            box-shadow: 0 0 10px 5px var(--default-bg-dark);
        }

        .mat-mdc-tab-header-pagination-disabled {
            box-shadow: none;
            display: none;
        }

        .mat-mdc-tab-header-pagination-chevron {
            border-color: var(--text-primary);
        }
    }
}

.gwo-input-field {
    input.mat-input-element {
        height: 30px;
    }

    .mat-mdc-form-field-error {
        font-style: normal;
        font-weight: 600;
        line-height: 0.6875rem;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: calc(var(--mat-form-field-container-height)/2.2);
    }
}

.--text-center {
    text-align: center;
}

.--color-red {
    color: var(--error-regular);
}

.--color-green {
    color: var(--warn-green-dark);
}

.--color-yellow {
    color: var(--color-yellow);
}

.--color-turquoise {
    color: var(--color-turquoise);
}

.--color-light {
    color: var(--primary-bg-light);
}

@for $i from 1 through 9 {
    .--fw-#{$i} {
        font-weight: #{$i}00;
    }
}

.mat-expansion-panel {
    .mat-expansion-indicator::after {
        color: inherit;
        width: 0.275rem;
        height: 0.275rem;
        margin-top: -0.575rem;
    }
}

.product-app-codes__header {

    .mat-content {
        flex: none;
    }
}


.error-snackbar {
    &.mat-mdc-snack-bar-container {
        .mdc-snackbar__surface {
            background-color: var(--error-dark);
        }
    }
}

.success-snackbar {
    &.mat-mdc-snack-bar-container {
        .mdc-snackbar__surface {
            background-color: var(--warn-green-dark);
        }
    }
}

.btn-link {
    background: none;
    font-style: normal;
    font-weight: 700;
    color: var(--text-primary);
    border: none;
    padding: 0;
    transition: color 0.1s;
    cursor: pointer;

    &:hover {
        color: var(--primary-850);
    }
}

.pt-1 {
    padding-top: 1rem;
}

.text-no-wrap {
    white-space: nowrap;
}

.mat-mdc-paginator-range-actions {
    color: var(--text-primary);
}

.my-gwo-slick-colors {
    .slick-dots li button:before {
        color: var(--primary-900) !important;
    }
}

.elipsis-2-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.elipsis-3-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.elipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.font-header {
    font-size: var(--font--size--big);
    font-weight: 600;
    color: var(--text-primary);
}

.font-header-secondary {
    font-size: var(--font--size--big);
    font-weight: 500;
    color: var(--text-primary);
}

.news-card {

    .news-button-link {
        border-radius: 0.75rem;
    }
}

.bold {
    font-weight: bold;
}

.--plain-text {
    margin: 0;

    small {
        color: var(--Primary-600, #9495CF);
        font-size: 0.71875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.9375rem;
    }
}

.--pointer {
    cursor: pointer;
}

.mdc-radio__outer-circle {
    background: var(--color-white);
}

.mdc-checkbox {
    align-self: flex-start;
}

.form-validation-message {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
}

.mdc-linear-progress.abs-progress {
    position: absolute;
}

.custom-carousel {
    .slick-dotted.slick-slider {
        margin-bottom: 3rem;
    }

    .slick-dots li button:before {
        font-size: 10px;
      }

    .slick-dots li {
        margin: 0;
    }
}

.more-link {
    position: relative;
    display: inline-block;

    &:after {
        content: "keyboard_arrow_down";
        font-family: "Material Icons", serif;
        font-weight: normal;
        font-style: normal;
        font-size: calc(100% + 0.325rem);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: auto;
    }

    &.active {

        &:after {
            content: "keyboard_arrow_up";
        }
    }
}

hr {
    border-style: solid;
    opacity: 0.5;
}

.mat-drawer {

    .mat-drawer-inner-container {
        overflow: unset;

        @include sm {
            overflow: auto;
        }
    }
}

.main-content {
    .mat-accordion {
        .mat-expansion-panel:not(.mat-expanded),
        .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
            border-radius: 0.75rem;
        }
    }

    .mat-accordion>.mat-expansion-panel-spacing:last-child,
    .mat-accordion>*:last-child:not(.mat-expansion-panel)
    .mat-expansion-panel-spacing {
        margin-bottom: 0.725rem;
    }
}

.mat-mdc-tab-body {
    opacity: 0;
    transition: opacity 0.2s;

    &.mat-mdc-tab-body-active {
        opacity: 1;
        width: 100%;
    }
}

.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body,
.mat-mdc-tab-body-content {
    overflow: unset!important;
}

.mat-mdc-tab-body-content {
    display: flex;
    flex-direction: column;
}

.mat-mdc-tab-body-wrapper {
    height: 100%;
}

.product-codes {

    .mat-expansion-panel-body {
        background: var(--default-bg-colored);
    }

    .mat-content {
        flex: none;
    }
}

.star-btn {
    cursor: pointer;

    &:hover {
        filter: brightness(0) saturate(100%) invert(88%) sepia(16%) saturate(5645%) hue-rotate(343deg) brightness(99%) contrast(97%);
    }

    .mdc-radio {
        display: none !important;
    }
}
