:root {
    // White palette
    --white-50: #F9F9F9;
    --white-100: #F8F7F6;
    --white-200: #F6F6F6;
    --white-300: #EEEEF5;
    --white-400: #ECECF9;
    --white-500: #ECECF9;
    --white-600: #F8F8F8;
    --white-700: #FFFFFF;
    --white-800: #FFFFFF;
    --white-900: #FFFFFF;

    // Primary palette
    --primary-50: #EEEEF9;
    --primary-100: #DADAFF;
    --primary-200: #D3D1EB;
    --primary-300: #C4C5EF;
    --primary-400: #FFFFFF;
    --primary-500: #9B9BC6;
    --primary-600: #9495CF;
    --primary-700: #AEAFF9;
    --primary-800: #9294ED;
    --primary-850: #6C6FBF;
    --primary-900: #5C5EB2;
    --primary-950: #4143a4;
    --primary-transparent: rgba(92, 94, 178, 0.3);

    // Warning palette
    --warning-600: #B7D7F8;

    // Single colors
    --color-white: var(--white-900);
    --color-orange: #FFA126;
    --color-yellow: #ffb048;
    --color-green: #82B21B;
    --color-turquoise: #58B4BE;
    --color-turquoise-dargk: #60BFB9;
    --color-cyan: #72B9EF;
    --color-blue: #2C89E5;
    --color-rose: #CD6BB0;
    --color-black: #000000;
    --color-violet: #8487D8;
    --color-light-gray: #70798230;
    --color-divider: #ECECF9;
    --color-text-underline-decorator: #adafe3;
    --calendar-past-date: #ECECF9;

    // Warnings
    --warn-blue: #B7D7F8;
    --warn-green-light: #C9E6BA;
    --warn-green-dark: #8FB444;
    --warn-yellow: #F8B81B;

    // Errors
    --error-light: #E8909D;
    --error-regular: #E68492;
    --error-dark: #D27380;
    --error-event-type-button: #F6B4BE;

    // Shadows
    --shadow-color-primary: rgba(0, 0, 0, 0.03);
    --shadow-color-light: #0000001a;
    --shadow-color-dark: #00000026;
    --shadow-aqua: #43ffac54;

    // Typography colors
    --link-color: var(--color-blue);
    --text-default: var(--color-black);
    --text-primary: var(--primary-900);
    --text-secondary: var(--primary-600);
    --text-gray: #69696C;

    // Backgrounds
    --default-bg: var(--white-900);
    --default-bg-dark: var(--white-200);
    --default-bg-colored: var(--white-500);
    --primary-bg-light: var(--primary-600);
    --primary-bg-dark:  var(--primary-800);
    --neutral-bg-light:  var(--primary-50);
    --success-notice-bg: var(--color-turquoise-dargk);
    --transparent-bg: rgba(243, 243, 243, 0.60);

    // Borders
    --border-light: var(--white-400);
    --primary-border: var(--primary-500);

    // Font sizes
    --font--size--small: 0.875rem;
    --font--size--normal: 1rem;
    --font--size--big: 1.5rem;

    // UI elements sizes
    --news-tile-width: 14.1875rem;
}
