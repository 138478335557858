@use '@angular/material' as mat;

html {
    --mat-form-field-container-height: 2.375rem;
    --mat-form-field-container-vertical-padding: 0.525rem;
    --mat-form-field-outlined-label-text-populated-size: 0.9rem;
    --mdc-outlined-text-field-label-text-color: var(--text-primary);
    --mdc-outlined-text-field-input-text-color: var(--text-primary);
    --mdc-filled-text-field-input-text-color: var(--text-primary);
    --mdc-outlined-text-field-disabled-input-text-color: var(--primary-300);
    --mdc-outlined-text-field-input-text-placeholder-color: var(--primary-bg-light);
    --mdc-outlined-text-field-hover-label-text-color: var(--text-primary);
    --mdc-outlined-text-field-outline-color: var(--primary-bg-light);
    --mdc-outlined-text-field-hover-outline-color: var(--primary-bg-light);
    //## icon button
    --mdc-icon-button-icon-color: inherit;
    --mat-icon-button-state-layer-color: var(--primary-900);
    --mat-icon-button-ripple-color: var(--primary-transparent);
    --mdc-checkbox-state-layer-size: 32px;
    --mdc-checkbox-unselected-icon-color: var(--text-primary);
    --mdc-checkbox-selected-icon-color: var(--warn-yellow);
    --mdc-checkbox-selected-hover-icon-color: var(--warn-yellow);
    --mdc-checkbox-selected-focus-icon-color: var(--warn-yellow);
    --mdc-checkbox-selected-pressed-icon-color: var(--warn-yellow);
    --mdc-checkbox-selected-checkmark-color: var(--color-white);
    //## stroke button
    --mdc-outlined-button-outline-color: inherit;
    --mdc-outlined-button-label-text-color: var(--text-primary);
    --mdc-outlined-button-outline-width: 2px;
    --mdc-outlined-button-container-shape: 2rem;
    --mat-outlined-button-state-layer-color: var(--text-primary);
    --mat-outlined-button-disabled-state-layer-color: var(--text-primary);
    --mat-outlined-button-ripple-color: var(--primary-100);
    --mdc-outlined-button-label-text-size: 0.875rem;
    --mdc-outlined-button-label-text-weight: 600;
    --mdc-outlined-button-container-height: 2.56rem;
    //## radio-btns button
    --mdc-checkbox-unselected-focus-state-layer-color: var(--text-primary);
    --mdc-checkbox-unselected-hover-state-layer-color: var(--text-primary);
    --mdc-checkbox-unselected-pressed-state-layer-color: var(--text-primary);
    --mat-divider-color: var(--color-divider);
    --mat-select-enabled-trigger-text-color: var(--text-primary);
    --mdc-radio-state-layer-size: 32px;
    //## mat-paginator
    --mat-paginator-container-text-color: var(--text-primary);
    --mat-paginator-container-background-color: transparent;
    --mat-paginator-enabled-icon-color: var(--text-primary);
    --mat-paginator-disabled-icon-color: var(--primary-transparent);
    //## mat-table
    --mat-table-background-color: white;
    --mat-table-header-headline-color: var(--text-primary);
    --mat-table-row-item-label-text-color: var(--text-primary);
    --mat-table-row-item-outline-color: var(--primary-700);
    --mat-expansion-header-expanded-state-height: 3.125rem;
    --mat-expansion-header-collapsed-state-height: 3.125rem;
    --mat-expansion-container-shape: 0.75rem;
    //## mat-list-item
    --mat-tree-node-min-height: 1.725rem;
    --mdc-list-list-item-one-line-container-height: 1.725rem;
    --mdc-list-list-item-label-text-color: var(--text-primary);
    --mat-tree-node-text-color: var(--text-primary);
    --mdc-icon-button-state-layer-size: 1rem;
    --mdc-list-list-item-hover-label-text-color: var(--text-primary);
    --mdc-list-list-item-focus-label-text-color: var(--text-primary);
    --mat-expansion-header-indicator-color: var(--text-primary);
    --mat-form-field-container-text-size: 0.95rem;
}

.mat-mdc-checkbox {
    --mdc-form-field-label-text-color: var(--text-primary);
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        background: var(--color-white);
    }
}

.mat-mdc-card-image.mat-mdc-card-xl-image {
    width: 100%;
    height: auto;
}

.mat-mdc-radio-button.mat-primary {
    --mdc-radio-unselected-icon-color: var(--text-secondary);
    --mdc-radio-selected-focus-icon-color: var(--warn-yellow);
    --mdc-radio-selected-hover-icon-color: var(--warn-yellow);
    --mdc-radio-selected-icon-color: var(--warn-yellow);
    --mdc-radio-selected-pressed-icon-color: var(--warn-yellow);
    --mdc-form-field-label-text-color: var(--text-primary);

    .mdc-radio {
        &:active,
        &:hover,
        & {
            .mdc-radio__inner-circle {
                border-color: transparent !important;
            }
        }

        .mdc-radio__native-control:enabled:checked + .mdc-radio__background {
            .mdc-radio__outer-circle {
                border-width: 5px;
            }
        }
    }
}
