@import '../../assets/styles/media';

.widget-mode .highlighted-calendar .mat-calendar-body-cell {
    transform: scale(0.76);
}

.widget-mode .highlighted-calendar .mat-calendar-body-cell:focus-visible {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.calendar-wrapper:not(.widget-mode) .highlighted-calendar .mat-calendar-body-cell-content {
    font-size: 1rem;

    @include lg {
        font-size: 1.1rem;
    }

    @include md {
        font-size: 1.1rem;
    }
}

.highlighted-calendar {

    .special-date .mat-calendar-body-cell-content {
        background-color: var(--warning-600) !important;
        border-radius: 50%;
    }

    .special-date:hover .mat-calendar-body-cell-content {
        background-color: var(--warning-600) !important;
        border-radius: 50% !important;
    }

    .past-special-date .mat-calendar-body-cell-content {
        background-color: var(--calendar-past-date) !important;
        border-radius: 50%;
    }

    .past-special-date:hover .mat-calendar-body-cell-content {
        background-color: var(--calendar-past-date) !important;
        border-radius: 50% !important;
    }

    .assigned-special-date .mat-calendar-body-cell-content {
        background-color: var(--warn-green-light) !important;
        border-radius: 50%;
    }

    .transparent .mat-calendar-body-cell-content {
        background-color: transparent !important;
        border-radius: 50%;
    }


    .assigned-special-date:hover .mat-calendar-body-cell-content {
        background-color: var(--warn-green-light) !important;
        border-radius: 50% !important;
    }

    .mat-calendar-header {
        padding: 10px 10px 0 10px;
    }

    .mat-calendar-content {
        padding: 0px 10px 10px 10px;
        outline: none;
    }

    .mat-calendar-previous-button {
        transform: rotate(90deg);
    }

    .mat-calendar-next-button {
        transform: rotate(90deg);
    }

    .mat-calendar-table-header-divider {
        display: none;
    }

    .mat-calendar-body-cell {
        transform: scale(0.7);
    }

    .mat-calendar-table-header {
        font-size: 0.53333333333rem;
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: transparent;
    }

    .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 39px;
    }

    .mat-calendar-arrow {
        display: none;
    }

    .mdc-button__label {
        text-transform: lowercase;
    }

    .mdc-button__label::first-letter {
        text-transform: uppercase;
    }

    .mat-calendar-period-button {
        pointer-events: none;
    }

    .mat-calendar-controls,
    .mdc-button__label,
    .mat-calendar-body-cell-content {
        color: var(--primary-900);
        font-weight: bold;
    }

    .mat-calendar-body-label {
        color: var(--primary-900);
    }

    .mat-calendar-table-header {
        th {
            color: var(--primary-900);
        }
    }

    .mat-calendar-body-active {
        color: transparent;

        .mat-calendar-body-today {
            border: 3px solid !important;
            border-color: var(--primary-900) !important;
        }
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        border: 3px solid !important;
        border-color: var(--primary-900) !important;
    }

    .mat-calendar-body-label {
        opacity: 0;
    }

    .mat-calendar-body-label[colspan="7"] {
        display: none;
    }
}
