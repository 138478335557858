// =============================================================================
// Media
// =============================================================================

$hd: 2560px;
$lg: 1500px;
$md: 1300px;
$sm: 960px;
$xs: 600px;

@mixin xs {
    @media (max-width: #{$xs}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$sm}) {
        @content;
    }
}

@mixin md {
    @media (max-width: #{$md}) {
        @content;
    }
}

@mixin lg {
    @media (max-width: #{$lg}) {
        @content;
    }
}

@mixin hd {
    @media (min-width: #{$hd}) {
        @content;
    }
}

@mixin container-padding {
    padding: 1rem 3rem;

    @include xs {
        padding: 2rem 1rem 6rem;
    }
}
