
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './assets/styles/palette' as gwo;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$moje-gwo-fe-new-primary: mat.define-palette(gwo.$purple-palette);
$moje-gwo-fe-new-accent: mat.define-palette(gwo.$white-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$moje-gwo-fe-new-warn: mat.define-palette(gwo.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$moje-gwo-fe-new-theme: mat.define-light-theme((
  color: (
    primary: $moje-gwo-fe-new-primary,
    accent: $moje-gwo-fe-new-accent,
    warn: $moje-gwo-fe-new-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($moje-gwo-fe-new-theme);

.main-container .mat-drawer-content {
  overflow: hidden;
}
